import React from 'react';
import './App.css';
import { Button, Table, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import {isMobile, isDesktop} from 'react-device-detect';
import {aspects, functions, reinins, types, subtypes, aspNames, funNames, aspAltNames, funAltNames, modelsA} from "./socioData";
import {compareNumbers, comparePoints, propType, socioText} from "./Const";
import './fonts/Socionic.TTF';



class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAspects: [],
            selectedFunctions: [],
            clues: [
                //  {id: "kahfwal", a: [1,2,3], f: [1,2,3], text: "some text"},
                //  {id: "kahfwal", a: [1,2,3], f: [1,2,3], text: "some text"}
            ],
            exes: false,
            text: "",
            visibleAdvancedAspects: false,
            visibleAdvancedFunctions: false,
            showAspFunAlarm: false,
            selectedClue: null,
            selectedType: null
        };

        this.componentRef = React.createRef();
    }


    componentDidMount () {

        this.setState(JSON.parse(localStorage.getItem('state')));
    };

    componentDidUpdate (prevProp, prevState, snapshot) {

        localStorage.setItem('state',JSON.stringify(this.state));
    };

    render() {

        let descriptionRender = <h5>Підбір типу за аспектами та функціями.</h5>;
        let normalButton = "light";
        let selectedButton = "secondary";


        // tools

        let getPropByClues = (clues, arr) => {

            if (clues.length === 0)
                return [];

            let result = [0, 1, 2, 3, 4, 5, 6, 7]; // aspects or func
            clues.forEach(pid => {   // [-1,3]

                let i = Math.abs(pid) - 1;  //id of aspect
                let value = pid > 0 ? 1 : 0; // + or -

                arr[i].value.forEach((v, ida) => {
                    if (v !== value) // not current pole
                        result = result.filter(n => n !== ida);
                });
            });

            // console.log("clues", clues, arr, result);
            return result;
        };


        let cluesMatrix =
            this.state.clues.map(c => {
                let matrix = [];
                let asp = getPropByClues(c.a, aspects);
                let fun = getPropByClues(c.f, functions);

                if (asp.length > 0 && fun.length > 0)
                    asp.forEach(a => fun.forEach(f => {
                            let pair = {a, f};
                            if (matrix.findIndex(p => p.a === pair.a && p.f === pair.f) < 0)
                                matrix.push(pair);
                        }
                    ));

                return matrix;
            }).flat();  //1d array

        // MODEAL A

        let modelARender = (id) => {

            if (id == null)
                id = 0;
            let model = modelsA[id];
            let drawList = [[0, 1], [3, 2], [5, 4], [6, 7]];

            return (
                <Table size="sm">
                    <thead>
                    <tr>
                        <th colSpan={2}>Модель А - {types[id].name}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        drawList.map((l, pairId) => {
                            return <tr key={pairId} className="">
                                {
                                    [0, 1].map(i => { //cell pair

                                        let points = cluesMatrix.filter(p => model[l[i]] === p.a && l[i] === p.f);

                                        return <td key={i} className="border">
                                            <div className="d-flex flex-nowrap justify-content-start px-1">
                                                <span className="text-secondary indexUp">{l[i] + 1}</span>&nbsp;
                                                <span
                                                    className="text-black aspects">{socioText(aspAltNames[model[l[i]]])}</span>
                                                &nbsp;&nbsp;
                                                {
                                                    points.map((p, idx) =>
                                                        <span key={idx} className="me-0-1">+</span>
                                                    )
                                                }
                                            </div>
                                        </td>
                                    })
                                }
                            </tr>
                        })
                    }
                    </tbody>
                </Table>);
        };


        // === TYPES ===

        let selectType = (id) => {
            this.setState({selectedType: id});
        };

        let getCluesIntersections = (t) => {

            let model = modelsA[t.id];
            return cluesMatrix.filter(p => model[p.f] === p.a);
        };

        let clonedArray = [...types];
        clonedArray.map((t) => {
            t.points = getCluesIntersections(t);
        });
        clonedArray.sort(comparePoints);

        let maxPoints = Math.max(...(clonedArray.map(t => t.points.length)));

        let typesListRender = <Table className="" size="sm">
            <thead>
            <tr>
                <th>Типи</th>
            </tr>
            </thead>
            <tbody>
            {
                clonedArray.map((t, idx) => {

                    let boltTop = maxPoints > 0 && t.points.length === maxPoints;
                    let pointText = t.points.length > 0 ? ` (${t.points.length}/${cluesMatrix.length})` : "";

                    let name = <span><span>{t.name}</span> <span className="small">{t.code}</span></span>;


                    return <tr key={idx}>
                        <td><Button key={idx}
                                    variant={normalButton}
                                    className="text-nowrap"
                                    size="sm"
                                    onClick={() => selectType(t.id)}>
                            {boltTop ? <b>{name}</b> : name}
                            {pointText}
                        </Button></td>
                    </tr>;
                })
            }
            </tbody>
        </Table>;


        // CLUES

        let drawAspects = (arr) => {
            return arr.length === 0 ? " " : arr.map(i => aspNames[i]).join(',')
        };
        let drawFunctions = (arr) => {
            return arr.length === 0 ? " " : arr.map(i => funAltNames[i]).join(',')
        };

        let clearClues =
            <Button size="sm" variant="light" className="mx-2" onClick={() => {
                this.setState({clues: [], selectedClue: null})
            }}>X</Button>;

        let selectClue = (id) => {

            let clue = this.state.clues[id];
            this.setState({
                selectedClue: clue.id,
                selectedAspects: [...clue.a],
                selectedFunctions: [...clue.f],
                text: clue.text
            });
        };

        let deleteClue = (id) => {

            let c = this.state.clues[id];
            this.setState({
                clues: this.state.clues.filter(ci => ci.id !== c.id),
                selectedClue: (this.state.selectedClue === c.id ? null : this.state.selectedClue)
            });
        };

        let clueTable = <Table size="sm">
            <thead>
            <tr>
                <th className="d-flex justify-content-between align-items-center">Перелік знахідок: {clearClues}</th>
            </tr>
            </thead>
            <tbody>{this.state.clues.length === 0 ?
                <tr className="border-white">
                    <td/>
                </tr> :
                this.state.clues.map((c, idx) =>
                    <tr key={idx}>
                        <td className="w-auto d-flex align-items-center">
                            <div>
                                <Button size="sm" variant="light" className="me-2"
                                        onClick={() => {
                                            deleteClue(idx)
                                        }}>X</Button>
                            </div>
                            <div className="w-auto" onClick={() => {
                                selectClue(idx);
                            }}>
                                <span className="small text-secondary">#{idx + 1}&nbsp;</span>
                                <b>{drawAspects(getPropByClues(c.a, aspects))}</b>&nbsp;
                                <i className="small">{drawFunctions(getPropByClues(c.f, functions))}</i>{c.text.length > 0 ? ":" : ""}&nbsp;
                                <span className="small">{c.text}</span>

                            </div>
                        </td>
                    </tr>)
            }
            </tbody>
        </Table>;

        let clueInputRender = <div className=""><Form onSubmit={(event)=> { event.preventDefault(); }}>
            <Form.Group className="mb-3" controlId="formClueText">
                <Form.Label className="small">
                    {this.state.selectedClue !== null ? "Редагувати #" + (this.state.clues.findIndex(c => c.id === this.state.selectedClue) + 1) : "Створити:"}
                </Form.Label>
                <Form.Control as="textarea" rows={2} className="w-100" value={this.state.text}
                              placeholder="Введіть фрагмент тексту для аналізу.."
                              onChange={(e) => {
                                  this.setState({text: e.target.value})
                              }}/>
            </Form.Group>
        </Form></div>;


        let clickPreviewProp = (type, id) => {
            let arrValues = (type === propType.aspects ?
                    (this.state.visibleAdvancedAspects ? [1, 2, 3, 4, 5, 6, 7] : [1, 2, 3]) :
                    (this.state.visibleAdvancedFunctions ? [1, 2, 3, 4, 5, 6, 7] : [1, 2, 3])
            ).map(
                (n, i) => (type === propType.aspects ? aspects : functions)[i].value[id] === 1 ? n : -n
            );
            //console.log(arrValues);
            this.setState({[type === propType.aspects ? "selectedAspects" : "selectedFunctions"]: arrValues})
        };

        let propPreviewButtons = (type) => {

            let selected = getPropByClues(
                type === propType.aspects ? this.state.selectedAspects : this.state.selectedFunctions,
                type === propType.aspects ? aspects : functions);

            return <div className="mb-2 mt-0">
                {(type === propType.aspects ? aspNames : funNames)
                    .map((pn, idx) => {
                        return <Button key={idx} size="sm" variant="light" className="me-1 mb-1"
                                       onClick={() => {
                                           clickPreviewProp(type, idx)
                                       }}>
                            {selected.includes(idx) ? <b>{pn}</b> : pn}</Button>
                    })}
            </div>;
        };


        // === PROPS TABLES ===

        let clearPropButton = (type) =>
            <Button size="sm" variant="light" className="mx-2" onClick={() => {
                this.setState({[type === propType.aspects ? "selectedAspects" : "selectedFunctions"]: []})
            }}>X</Button>;

        let selectProp = (id, state, type) => {

            let arr = type === propType.aspects ? this.state.selectedAspects : this.state.selectedFunctions;
            let value = (id + 1) * (state ? 1 : -1);  // manually changed id

            if (arr.find(v => v === value)) {
                //   console.log("removed ", value);
                arr = arr.filter(v => v !== value);
            }
            else {
                arr.push(value);
                if (arr.includes(-value)) {
                    arr.splice(arr.findIndex(n => n === -value), 1);
                    //     console.log("removed ", -value);
                }
                // console.log("added ", value);
            }

            this.setState({[type === propType.aspects ? "selectedAspects" : "selectedFunctions"]: arr});
        };

        let advancedChanged = (type) => {

            let arr = type === propType.aspects ? "visibleAdvancedAspects" : "visibleAdvancedFunctions";
            let value = type === propType.aspects ? !this.state.visibleAdvancedAspects : !this.state.visibleAdvancedFunctions;

            let asp = this.state.selectedAspects;
            let fun = this.state.selectedFunctions;

            /*
            if (value === false) {  // hide and clear hidden
                if (type === propType.aspects)
                    asp =  this.state.selectedAspects.filter(a => Math.abs(a) < 4);
                else
                    fun = this.state.selectedFunctions.filter(a => Math.abs(a) < 4);
            }
            */

            this.setState({[arr]: value, selectedAspects: asp, selectedFunctions: fun});
        };

        let advancedButton = (state, type) => {

            let arr = type === propType.aspects ? this.state.selectedAspects : this.state.selectedFunctions;
            let hiddenProps = arr.filter(p => Math.abs(p) > 3).length;

            return (
                <Button
                    variant={normalButton}
                    size="sm"
                    onClick={() => advancedChanged(type)}>
                    {state ? "-" : hiddenProps > 0 ? "+" + hiddenProps : "+"}
                </Button>);
        };


        let propTableRender = (type) => {

            let selected = type === propType.aspects ? this.state.selectedAspects : this.state.selectedFunctions;
            let isAdvanced = (type === propType.aspects && this.state.visibleAdvancedAspects) ||
                (type === propType.functions && this.state.visibleAdvancedFunctions);

            return (
                <Table className="w-auto" size="sm">
                    <thead>
                    <tr>
                        <th colSpan={this.state.exes ? 2 : 1}
                            className="d-flex justify-content-between align-items-center">
                            {type === propType.aspects ? "Aспект" : "Функція"}
                            <div>
                                {advancedButton(isAdvanced, type)}
                                {clearPropButton(type)}
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>{(type === propType.aspects ? aspects : functions)
                        .slice(0, isAdvanced ? 7 : 3)
                        .map((prop, propId) => {

                                //let pmStyle = "small text-secondary mx-1";
                                let exesCell = this.state.exes ?
                                    <td className="small pt-2"><span className="text-muted">x{prop.x}</span></td> : null;

                                return (
                                    <tr key={propId}
                                        className={propId === 2 ? "border-bottom border-dark" : ""}>
                                        {exesCell}
                                        <td className="w-50">
                                            <div className="d-flex flex-nowrap justify-content-between">
                                                <Button
                                                    variant={selected.find(asp =>
                                                        asp === (propId + 1)) !== undefined ? selectedButton : normalButton}
                                                    size="sm"
                                                    onClick={() => selectProp(propId, 1, type)}>
                                                    {prop.plus}</Button>
                                                <Button
                                                    variant={selected.find(asp =>
                                                        asp === -(propId + 1)) !== undefined ? selectedButton : normalButton}
                                                    size="sm"
                                                    onClick={() => selectProp(propId, 0, type)}
                                                    className="mx-2">
                                                    {prop.minus}</Button>
                                            </div>
                                        </td>
                                    </tr>);

                            }
                        )}
                    </tbody>
                </Table>
            )
        };


        // SUBMIT CLUE

        let alarmRender = <p className="text-danger small">Визначте аспект та функцію більш точно (1-2 шт)</p>;

        const handleSubmit = (event) => {
            event.preventDefault();
            // convert selected asp & fun & text to clue

            let asp = getPropByClues(this.state.selectedAspects, aspects);
            let fun = getPropByClues(this.state.selectedFunctions, functions);

            if ((asp.length === 0 && fun.length === 0) || asp.length > 2 || fun.length > 2) {
                this.setState({showAspFunAlarm: true});
                setTimeout(() => {
                    this.setState({showAspFunAlarm: false});
                }, 3000);
                return;
            }

            let clues = this.state.clues;
            clues.push(
                {
                    id: makeid(8),
                    a: [...this.state.selectedAspects],
                    f: [...this.state.selectedFunctions],
                    text: this.state.text + ""
                }
            );

            this.setState({clues: clues, text: "", selectedAspects: [], selectedFunctions: []});
        };

        let handleSave = () => {
            console.log("save");
            let clue =
                {
                    id: this.state.selectedClue,
                    a: this.state.selectedAspects,
                    f: this.state.selectedFunctions,
                    text: this.state.text
                };

            this.setState({
                clues: this.state.clues.map(c => c.id === clue.id ? clue : c),
                text: "", selectedAspects: [], selectedFunctions: [], selectedClue: null
            });
        };

        let handleCancel = () => {
            this.setState({selectedClue: null, selectedAspects: [], selectedFunctions: [], text: ""})
        };

        let addButton = <Button variant="primary" onClick={handleSubmit}>Додати</Button>;
        let saveButton = <Button variant="primary" onClick={handleSave}>Зберегти</Button>;
        let unSelectButton = <Button variant="info" className="mx-2" onClick={handleCancel}><span
            className="text-white">Відмінити</span></Button>;


        let exportButton = <div>
            <Button variant="success" className="small" onClick={() => {

                let date = new Date().toLocaleDateString() + "-"+ new Date().toLocaleTimeString();
                let name = "modela.socio.club-" + types[this.state.selectedType ? this.state.selectedType : 0].name + "-" + date + ".jpg";

                exportComponentAsJPEG(this.componentRef, {fileName: name});
            }}>
            <span className="small">Експорт JPG</span>
        </Button></div>;


        let exesCheckmark = <form>
            <div className="form-check">
                <input type="checkbox" defaultChecked={this.state.exes} className="form-check-input" id="exampleCheck1"
                       onChange={() => {
                           this.setState({exes: !this.state.exes});
                       }}/>
                <label className="form-check-label" htmlFor="exampleCheck1">
                    <small>Показати зв'зки</small>
                </label>
            </div>
        </form>;


        let pageRender = (
            <div className="container-fluid p-0"  >
                {descriptionRender}
                <div className="row" ref={this.componentRef}>
                    <div className="col-8">
                        {clueTable}
                        {clueInputRender}
                        <div className="row col-sm col-md">
                        <div className="col">
                            {propPreviewButtons(propType.aspects)}
                            {propTableRender(propType.aspects)}
                        </div>
                        <div className="col">
                            {propPreviewButtons(propType.functions)}
                            {propTableRender(propType.functions)}
                        </div>
                        </div>
                        {this.state.showAspFunAlarm ? alarmRender : null}
                        {this.state.selectedClue ?
                            <div className="d-flex">{saveButton}{unSelectButton}</div> : addButton}
                    </div>
                    <div className="col-4">
                        {modelARender(this.state.selectedType)}
                        {typesListRender}
                    </div>
                </div>
                {isDesktop ? exportButton : null}
                <p>&nbsp;</p>
            </div>
        );

        /*
        let getTypeModel = (idx) => {

            //1,2,4,10
            let intuit = reinins[0].value[idx];
            let logic = reinins[1].value[idx];
            let extravert = reinins[3].value[idx];
            let irracional = reinins[9].value[idx];

            let f1 = irracional ? (intuit ? (extravert ? 7 : 6) : (extravert ? 0 : 1)) : (logic ? (extravert ? 3 : 2) : (extravert ? 4 : 5));
            let f2 = irracional ? (logic ? (extravert ? 2 : 3) : (extravert ? 5 : 4)) : (intuit ? (extravert ? 6 : 7) : (extravert ? 1 : 0));
            let f3 = 8 - (f1 + 1);
            let f4 = 8 - (f2 + 1);
            let f7 = irracional ? (intuit ? (extravert ? 6 : 7) : (extravert ? 1 : 0)) : (logic ? (extravert ? 2 : 3) : (extravert ? 5 : 4));
            let f8 = irracional ? (logic ? (extravert ? 3 : 2) : (extravert ? 4 : 5)) : (intuit ? (extravert ? 7 : 6) : (extravert ? 0 : 1));
            let f5 = 8 - (f7 + 1);
            let f6 = 8 - (f8 + 1);

            return [f1,f2,f3,f4,f5,f6,f7,f8];
        };

        let genModels = () => {

            return types.map((type,idx)=> getTypeModel(idx));

        };

        console.log("models", genModels());
        */

        return (pageRender);
    };
}



function makeid(length) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export default Content;
