import './App.css';
import Content from "./Content";
import { Button } from 'react-bootstrap';

function App() {


    let captionRender =
        <div className="d-flex">
            <h3>Соціоніка: Калькулятор типу за Моделлю А</h3>
        </div>;

    let footerRender = (
        <div className="footer">
            <p>(c) <a href="https://fb.com/ilyxa3d" target="_black">Ілля Кравченко</a>, 2022</p>
        </div>
    );

    return (
        <div className="mainContainer">
            {captionRender}
            <Content/>
            {footerRender}
        </div>
    )
}


export default App;
