import React from 'react';

const propType = {aspects: "aspects", functions: "functions", reinins: "reinins"};
const log = false;

const compareNumbers = (a, b) => {
    return a - b;
};

const comparePoints = (a, b) => {
    return b.points.length - a.points.length;
};

let socioText = (text) => {

    return <span className="font-socio">{text}</span>;
};

export {propType, log, compareNumbers, socioText, comparePoints};