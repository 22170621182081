
let aspects = [
    {plus: "Конкретний", minus: "Абстрактний", value: [1, 1, 1, 1, 0, 0, 0, 0], x: 1},
    {plus: "Статичний", minus: "Динамічний", value: [1, 0, 1, 0, 0, 1, 0, 1], x: 2},
    {plus: "Зовнішній", minus: "Внутрішній", value: [1, 0, 0, 1, 1, 0, 0, 1], x: 3},
    {plus: "Рушійний", minus: "Інерційний", value: [1, 0, 1, 0, 1, 0, 1, 0], x: 12},
    {plus: "Виконавчий", minus: "Цілестворюючий", value: [1, 0, 0, 1, 0, 1, 1, 0], x: 13},
    {plus: "Сприймаючий", minus: "Судящий", value: [1, 1, 0, 0, 0, 0, 1, 1], x: 23},
    {plus: "Залучений", minus: " Сторонній", value: [1, 1, 0, 0, 1, 1, 0, 0], x: 123}
];

let functions = [
    {plus: "Ментальна", minus: "Вітальна", value: [1, 1, 1, 1, 0, 0, 0, 0], x: 1},
    {plus: "Шаблонна", minus: "Ситуативна", value: [1, 0, 1, 0, 0, 1, 0, 1], x: 2},
    {plus: "Завантажена", minus: "Розвантажена", value: [1, 0, 0, 1, 1, 0, 0, 1], x: 3},
    {plus: "Акцептна", minus: "Продуктивна", value: [1, 0, 1, 0, 1, 0, 1, 0], x: 12},
    {plus: "Інертна", minus: "Контактна", value: [1, 0, 0, 1, 0, 1, 1, 0], x: 13},
    {plus: "Сильна", minus: "Слабка", value: [1, 1, 0, 0, 0, 0, 1, 1], x: 23},
    {plus: "Вербальна", minus: "Невербальна", value: [1, 1, 0, 0, 1, 1, 0, 0], x: 123}
];

let reinins = [
    {name: "Інтуїт Сенсорик", value: [1, 0, 0, 1, 1, 0, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0]},
    {name: "Логік Етик", value: [1, 0, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0, 1, 0, 0, 1]},
    {name: "Статик Динамік", value: [1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1, 1, 0]},
    {name: "Екстраверт Інтроверт", value: [1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0]},
    // kavadra
    {name: "Демократ Аристократ", value: [1, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0]},
    {name: "Розважливий Рішучий", value: [1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1]},
    {name: "Веселий Серьозний", value: [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0]},
    //dual
    {name: "Безпечний Передбачливий", value: [1, 1, 0, 0, 1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 1, 1]},
    {name: "Поступливий Упертий", value: [1, 1, 0, 0, 0, 0, 1, 1, 0, 0, 1, 1, 1, 1, 0, 0]},
    {name: "Ірраціонал Раціонал", value: [1, 1, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 1, 1]},
    //indiv
    {name: "Квестім Деклатім", value: [1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1]},
    {name: "Позитивіст Негативіст", value: [1, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 1]},
    {name: "Тактик Стратег", value: [1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0]},
    {name: "Конструктивіст Емотивіст", value: [1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1]},
    //dyal
    {name: "Процесор Результатер", value: [1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0]}
];

let modelsA = [
    [7,2,0,5,1,4,6,3],[1,4,6,3,7,2,0,5],[4,1,3,6,2,7,5,0],[2,7,5,0,4,1,3,6],
    [4,6,3,1,2,0,5,7],[2,0,5,7,4,6,3,1],[0,2,7,5,6,4,1,3],[6,4,1,3,0,2,7,5],
    [0,5,7,2,6,3,1,4],[6,3,1,4,0,5,7,2],[3,6,4,1,5,0,2,7],[5,0,2,7,3,6,4,1],
    [3,1,4,6,5,7,2,0],[5,7,2,0,3,1,4,6],[7,5,0,2,1,3,6,4],[1,3,6,4,7,5,0,2]
];

let types = [
    {id: 0, code: "ІЛЕ", name: "Шукач", model: []},
    {id: 1, code: "СЕІ", name: "Посередник"},
    {id: 2, code: "ЕСЕ", name: "Ентузіаст"},
    {id: 3, code: "ЛІІ", name: "Аналітик"},
    {id: 4, code: "ЕІЕ", name: "Наставник"},
    {id: 5, code: "ЛСІ", name: "Інспектор"},
    {id: 6, code: "СЛЕ", name: "Маршал"},
    {id: 7, code: "ІЕІ", name: "Лірик"},
    {id: 8, code: "СЕЕ", name: "Політик"},
    {id: 9, code: "ІЛІ", name: "Критик"},
    {id: 10, code: "ЛІЕ", name: "Підприємець"},
    {id: 11, code: "ЕСІ", name: "Хранитель"},
    {id: 12, code: "ЛСЕ", name: "Адміністратор"},
    {id: 13, code: "ЕІІ", name: "Гуманіст"},
    {id: 14, code: "ІЕЕ", name: "Порадник"},
    {id: 15, code: "СЛІ", name: "Майстер"}
];




let subtypes = [
    {id: 0, name: "Домінантний", code: "D"},
    {id: 1, name: "Креативний", code: "C"},
    {id: 2, name: "Нормуючий", code: "N"},
    {id: 3, name: "Гармонызуючий", code: "H"}
];

let aspNames = ["ЧС","БС","БЛ","ЧЛ","ЧЕ","БЕ","БІ","ЧІ"	];
let aspAltNames = ["F","S","L","P","E","R","T","I"];
let funNames = ["Ф1","Ф2","Ф3","Ф4","Ф5","Ф6","Ф7","Ф8"];
let funAltNames = ["Базова","Творча","Рольова","Больова","Навіювана","Активаційна","Обмежувальна","Фонова"];

export { aspects, functions, reinins, types, subtypes, aspNames, funNames, aspAltNames, funAltNames, modelsA };